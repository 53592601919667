import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'
import * as moment from 'moment'
import Hebcal from 'hebcal'

// Components
import Layout from '../components/Layout'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={3}>
      <Hero>
        <BigTitle>
          Hi! 👋 <br /> I'm Michael Wojcik.
        </BigTitle>
        <Subtitle>Web dev. Drupal pro. JavaScript fan.</Subtitle>
      </Hero>
      <About>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Michael Wojcik" />
          <AboutSub>
            I've been working as a full-time Drupal web developer for over {moment("2014", "YYYY").fromNow(true)} and counting.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          I'm a PHP and JavaScript web developer, currently working as a Senior Developer at <a href="https://lastcallmedia.com/">Last Call Media</a>. Previously, I was a Senior Developer at <a href="https://www.hook42.com">Hook 42</a> and, prior to that, a Drupal Developer at <a href="https://www.elevatedthird.com">Elevated Third</a> and <a href="https://www.mediacurrent.com">Mediacurrent</a>.
          I completed my master's degree in Information Technology at Harvard University in 2015.
          Additionally, I have a professional background in higher education and research administration (at Duke University and Harvard University) and in freelance web development.
        </AboutDesc>
      </About>
      <Contact>
        <Inner>
          <Title>Let's Chat</Title>
          <ContactText>
            Find me on{' '}
            <a href="https://www.drupal.org/u/michael_wojcik" title="Drupal.org">Drupal.org</a>, { }
            <a href="https://www.linkedin.com/in/michaeljohnwojcik" title="LinkedIn">LinkedIn</a>, and{' '}
            <a href="https://github.com/michael-wojcik" title="GitHub">GitHub</a>
          </ContactText>
        </Inner>
        <Footer>
          <p>&copy; {moment().format('YYYY')} / {(new Hebcal()).year} by Michael Wojcik.</p>
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
